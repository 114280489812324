import React, {useContext, useEffect} from "react";
import { StoreContext } from "../../../../stores/StoreLoader";
import { Toggle } from "../EditBlockComponents/EditBlockInputs/Toggle";
import FontAwesome from "../../../utilities/FontAwesome";
import WatsonApi from "../../../../backends/WatsonApi";
import styles from "../styles/SchoolFeedBlock.module.scss";
import {
    SchoolFeedContentItem,
} from "../../../../stores/SchoolFeedStore";
import classNames from "classnames";

interface FeedHeaderProps {
    currentView: string;
    handleCurrentViewChange: (view: string) => void;
    content: SchoolFeedContentItem[];
    currentViewOptions: { value: string; label: string }[];
    mutate: () => void;
}

const FeedHeader = (props: FeedHeaderProps) => {
    const { userStore, sidebarStore } = useContext(StoreContext);
    const [isSmallBlock, setIsSmallBlock] = React.useState(false);
    const feedHeaderClassNames = classNames({
        [styles.feedHeader]: true,
        [styles.feedHeaderSmallBlock]: isSmallBlock,
    })
    const headerRef = React.useRef<HTMLDivElement>(null);
    const adminButtonContainerClassNames = classNames({
        [styles.adminButtonContainer]: true,
    });
    useEffect(() => {
        const element = headerRef.current;
        if (element) {

            const resizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    setIsSmallBlock(entry.contentRect.width < 300);
                }
            });
            resizeObserver.observe(element);
            return () => {
                if (element) {
                    resizeObserver.unobserve(element);
                }
            };
        }
    }, []);
    return (
        <div className={feedHeaderClassNames} ref={headerRef}>
            <div className={styles.toggleButtonContainer}>
                <Toggle
                    value={props.currentView}
                    onChange={props.handleCurrentViewChange}
                    options={props.currentViewOptions}
                    title={"Switch between news and events"}
                    className={styles.toggleButtons}
                />
            </div>
            {userStore.isEditor && (
                <div className={adminButtonContainerClassNames}>
                    <button
                        className={styles.adminButton}
                        onClick={e => {
                            e.stopPropagation();
                            sidebarStore.setSidebar({
                                view: "SchoolFeedPost",
                                handleSave: async (contentItem, organization, post_to_facebook) => {
                                    const client = await WatsonApi();
                                    await client.apis.organizations.organizations_content_items_create({
                                        organization_pk: organization.id,
                                        post_to_facebook: post_to_facebook,
                                        data: contentItem,
                                    });
                                    await props.mutate();
                                },
                            });
                        }}
                        title={"Add New Post"}
                    >
                        <FontAwesome prefix={"fas"} name={"fa-plus"} />
                    </button>
                    <button
                        className={styles.adminButton}
                        onClick={e => {
                            e.stopPropagation();
                            sidebarStore.setSidebar({ view: "Social" });
                        }}
                        title={"Manage Sources"}
                    >
                        <FontAwesome prefix={"fas"} name={"fa-cog"} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default FeedHeader;
